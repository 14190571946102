import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/UI/Layout"
import Title from "../components/UI/Title"
import Button from "../components/UI/Button"
import Text from "../components/UI/Text"
import SideBySide from "../components/UI/SideBySide"
import Photo from "../components/UI/Photo"
import Offices from "../components/Offices"
import Team from "../components/Team"
import Spacer from "../components/UI/Spacer"
import Card from "../components/UI/Card"
import ClientsWall from "../components/ClientsWall"
import { getImageBySize } from "../utils"
import PhotoWall from "../components/UI/PhotoWall"
import Feature from "../components/UI/Feature"
import HelpWall from "../components/UI/HelpWall"
import Reviews from "../components/UI/Reviews"
import Iframe from "../components/UI/Iframe"
import { mapDynamicComponent } from "./page"

const Page = ({ data }) => {
  const {
    name,
    description,
    photos,
    catchphrase,
    sections,
    path,
    locale,
  } = data.product

  return (
    <>
      <Layout
        path={path}
        title={name}
        description={catchphrase}
        locale={locale}
      >
        {sections.map((section, index) =>
          mapDynamicComponent(section, index, locale)
        )}
      </Layout>
    </>
  )
}

export const query = graphql`
  query ProductQuery($id: String) {
    product: strapiProduct(id: { eq: $id }) {
      name
      catchphrase
      description {
        data {
          description
        }
      }
      path
      locale
      photos {
        url
        ext
        alternativeText
      }
      sections {
        ... on STRAPI__COMPONENT_PAGE_FEATURES {
          id
          title {
            content
            secondary
            level
          }
          features {
            label
            name
            featureDescription {
              data {
                featureDescription
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_UI_BUTTON {
          id
          label
          url
          variant
          align
        }
        ... on STRAPI__COMPONENT_UI_IMAGE_GALLERY {
          id
          image {
            ext
            alternativeText
            url
          }
        }
        ... on STRAPI__COMPONENT_UI_PAGE_GALLERY {
          id
          pageLayout: layout
          pages {
            path
            locale
            title
            description
            updatedAt
            cover {
              alternativeText
              ext
              url
            }
            id
          }
          id
          productLayouts: layout
          products {
            name
            path
            catchphrase
            catchphrases {
              data {
                catchphrases
              }
            }
            locale
            updatedAt
            photos {
              ext
              alternativeText
              url
            }
          }
        }
        ... on STRAPI__COMPONENT_UI_PHOTO_GALLERY {
          id
          photos {
            alternativeText
            ext
            url
            caption
          }
        }
        ... on STRAPI__COMPONENT_UI_SPACER {
          id
          size
        }
        ... on STRAPI__COMPONENT_UI_STEPS {
          id
          Block {
            Number
            Label {
              content
              secondary
              level
              lessWide
            }
            Text {
              text {
                data {
                  text
                }
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_UI_TEXT {
          id
          lessWide
          text {
            data {
              text
            }
          }
        }
        ... on STRAPI__COMPONENT_UI_TEXT_PHOTO {
          id
          textPhotoText: text {
            text {
              data {
                text
              }
            }
          }
          title {
            content
            level
            secondary
          }
          images {
            url
            ext
          }
          buttons {
            id
            label
            url
            variant
            align
          }
          layout
          Background
        }
        ... on STRAPI__COMPONENT_UI_TITLE {
          id
          content
          secondary
          level
          lessWide
        }
      }
    }
  }
`
export default Page
